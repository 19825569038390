import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/sleuth-meta.png'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import { responsiveScale } from '../../styles/helpers'
import GartnerTestimonials from '../../components/GartnerTestimonials'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/initiatives-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/slack-transparent.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const SleuthPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Sleuth vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Sleuth and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Sleuth vs. Swarmia</>}
        content="Sleuth helps teams improve developer productivity by tracking DORA metrics. For organizations looking for a comprehensive engineering productivity solution, Swarmia is the right choice."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading="Go beyond DORA and developer&nbsp;reviews"
        content="Swarmia gives you the visibility and tools your organization needs to make informed choices about priorities, people, and processes."
      />
      <QuoteMosaicBlock
        title="Make decisions based on metrics you can trust"
        content={
          <>
            Unlike Sleuth, which primarily focuses on DORA, Swarmia offers a
            more comprehensive understanding of your team’s productivity and
            it’s impact on the feature delivery.
            <br />
            <br />
            Swarmia combines data from GitHub and issue tracker for a real-time
            view of your organizations’s performance. The data on Swarmia is
            granular, which allows high-level tracking of strategic initiatives,
            or detailed insights into initiatives and teams.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.walter}
      />
      <QuoteMosaicBlock
        title="Deliver on planned business&nbsp;outcomes"
        content={
          <>
            While Sleuth provides developer pulse reports, Swarmia equips
            engineering leaders with actionable tools to manage the entire
            engineering organization.
            <br />
            <br />
            Swarmia’s insights into initiatives allow engineering leaders to
            estimate development costs, identify delivery risks, and effectively
            communicate with non-technical stakeholders.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.roland}
      />
      <QuoteMosaicBlock
        title={<>Get a holistic picture of your engineering organization</>}
        content={
          <>
            To understand the pulse of your engineering organization, you need
            to pair system metrics with survey data.
            <br />
            <br />
            Swarmia’s anonymous engineering surveys are designed to reveal and
            quantify the developer feedback. With Sleuth, you need to solely
            rely on system metrics.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.dave3}
      />
      <QuoteMosaicBlock
        title="Give your engineers tools to&nbsp;succeed"
        content={
          <>
            In addition to developer productivity insights, Swarmia’s working
            agreements and sophisticated GitHub-Slack notifications reduce
            interruptions and keep developers in the flow.
            <br />
            <br />
            Sleuth, on the other hand, focuses mainly on tracking developer
            performance, without offering advanced tools to effectively drive
            developer experience.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
        quote={quotes.silvia}
      />
      <QuoteBlock person="maryanna" />
      <CTABlock title={<>Get started with Swarmia today.</>} />
      <GartnerTestimonials />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/size-age-culture-productivity/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default SleuthPage
